import { ReactNode, useEffect, useState } from 'react';
import Link from 'next/link';
import { NavHamburger } from '@/components/Layouts';
import styles from './index.module.less';
import { AppSettings } from '@/shared/app-common';
import UserContent from '../UserContent';
// import { fetchHairstyleRecordsBalanceData } from '@/lib/service';
import { useRouter } from 'next/router';
import { useUserProvider } from '@/context/UserProvider';

const MobileHeader = ({ children }: { children?: ReactNode[] }) => {
  const router = useRouter();
  // const [hasGift, setHasGift] = useState(hasGiftVal);
  const { isLogin, isShowGiftView, onShowGiftView } = useUserProvider();
  // const loadBalanceList = () => {
  //   fetchHairstyleRecordsBalanceData()
  //     .then((res) => {
  //       if (res?.code === 0) {
  //         setHasGift(res?.data?.has_survey_reward);
  //       }
  //     })
  //     .catch((e) => {
  //       // message.error(e?.message);
  //     });
  // };
  const handleBannerClick = () => {
    router.push('/feedback'); // Replace with your target page URL
  };
  const handleCloseClick = () => {
    onShowGiftView(false);
  };

  // useEffect(() => {
  //   // loadBalanceList();
  //   setHasGift(hasGiftVal)
  // }, [hasGiftVal]);

  return (
    <div className={styles.mobileHeaderWarp}>
      {isLogin && isShowGiftView ? (
        <div className={styles.mobileBanner} style={{ backgroundColor: '#665DE7' }}>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
              fontSize: '14px',
              height: 52,
            }}
            onClick={handleBannerClick}
          >
            <img
              src={'/images/hairstyle/survey.png'}
              alt={''}
              width={19}
              style={{ marginRight: 8 }}
            />
            <span style={{ marginRight: 40 }}>Earn Free Try-Ons with Feedback</span>
            <img
              src={'/images/hairstyle/gift3.png'}
              alt={''}
              width={100}
              height={52}
              style={{ position: 'absolute', top: '0', right: '0' }}
            />
          </div>
          <button
            onClick={handleCloseClick}
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              background: 'none',
              border: 'none',
              color: '#fff',
              fontSize: '24px',
              cursor: 'pointer',
            }}
          >
            &times;
          </button>
        </div>
      ) : null}
      <div className={styles.navToolbar}>
        <div className={styles.navToolbarSection}>
          <NavHamburger>{children}</NavHamburger>
        </div>

        <div className={styles.navToolbarSection}>
          <Link href="/" className={styles.logo}>
            <img
              className={styles.pietra}
              src={AppSettings.logoPath}
              alt={AppSettings.name}
              title={AppSettings.name}
              loading="lazy"
            />
            <img
              src={AppSettings.namePath}
              alt={AppSettings.name}
              style={{ height: '32px', paddingLeft: '10px' }}
              loading="lazy"
            />
          </Link>
        </div>

        <div className={styles.navToolbarSection}>
          <div className={styles.userContent}>
            <UserContent />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileHeader;
