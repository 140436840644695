import Link from 'next/link';
import { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Button, Dropdown, MenuProps, Popover } from 'antd';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { fetchHairstyleRecordsBalanceData } from '@/lib/service';
import { bold } from 'next/dist/lib/picocolors';

const UserContent = () => {
  const {
    isLogin,
    shareUnreadCount,
    creditsCount,
    onCreditsChanged,
    onOpenLoginModal,
    onChangedLoginView,
    onSignOut,
  } = useUserProvider();
  const [firstLetter, setFirstLetter] = useState<string>('');
  const { t } = useTranslation('common');
  const router = useRouter();
  const [hasGift, setHasGift] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [popVisible, setPopVisible] = useState(false);

  const items: MenuProps['items'] = [
    {
      key: 'settings',
      label: <Link href={'/settings'}>{t('Settings')}</Link>,
    },
    {
      key: 'my-credits',
      label: <Link href={'/my-credits'}>{t('My Credits')}</Link>,
    },
    {
      key: 'billing-records',
      label: <Link href={'/billing-records'}>{t('PaymentHistory')}</Link>,
    },
    {
      key: 'logout',
      label: (
        <Link href={'/'} onClick={() => onSignOut()}>
          {t('LogOut')}
        </Link>
      ),
    },
  ];

  const loadBanaceList = () => {
    fetchHairstyleRecordsBalanceData()
      .then((res) => {
        if (res?.code === 0) {
          onCreditsChanged(res?.data?.balance);
          setHasGift(res?.data?.has_survey_reward);
          setPopVisible(res?.data?.has_survey_reward);

          if (res?.data?.has_survey_reward) {
            setTimeout(() => {
              setPopVisible(false);
            }, 3000);
          }
        }
      })
      .catch((e) => {
        // message.error(e?.message);
      });
  };
  useEffect(() => {
    loadBanaceList();
  }, [isLogin, creditsCount]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (user?.email?.length > 0) {
          const tempLetter = user?.email[0];
          setFirstLetter(tempLetter.toLocaleUpperCase());
        } else {
          setFirstLetter('');
        }
      } else {
        setFirstLetter(undefined);
      }
    });
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check initial screen size
    window.addEventListener('resize', handleResize);
    return () => {
      if (isLogin) {
        unsubscribe();
      } else {
        setFirstLetter(undefined);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [isLogin]);

  // const content = (
  //   <div
  //     style={{ width: 348 }}
  //     onClick={() => {
  //       setPopVisible(false);
  //     }}
  //   >
  //     <div style={{ fontWeight: bold('bold'), display: 'flex', alignItems: 'center' }}>
  //       <span style={{ color: '#ffffff', marginLeft: 5 }}>Get More Free Tries </span>
  //       <Image
  //         style={{ marginLeft: 15 }}
  //         src={'/images/hairstyle/gift2.png'}
  //         alt={''}
  //         width={40}
  //         height={40}
  //       />
  //     </div>
  //     <Button
  //       style={{ height: 54, width: '100%', borderRadius: 8 }}
  //       type="primary"
  //       onClick={() => {
  //         router.push('/feedback');
  //       }}
  //     >
  //       <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
  //         <div style={{ flex: 1, textAlign: 'left' }}>
  //           Submit
  //           <br />
  //           Feedback
  //         </div>
  //         <span
  //           style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
  //         >
  //           +10
  //           <Image src={'/images/hairstyle/credit_icon.png'} alt={''} width={30} height={30} />
  //         </span>
  //         <span style={{ flex: 1, textAlign: 'right' }}>Go Submit</span>
  //       </div>
  //     </Button>
  //   </div>
  // );
  return (
    <div className={styles.content}>
      {firstLetter != undefined ? (
        <div className={styles.loginDiv}>
          <div className={styles.creditDiv}>
            {/* {!isMobile && hasGift ? (
              <Popover
                placement="bottomRight"
                title={''}
                content={content}
                trigger="hover"
                open={popVisible}
                color={'#333333'}
                autoAdjustOverflow={false}
              >
                <Image
                  style={{ marginRight: 30 }}
                  onMouseEnter={() => setPopVisible(true)}
                  onMouseLeave={() => setPopVisible(false)}
                  src={'/images/hairstyle/gift.png'}
                  alt={''}
                  width={30}
                  height={30}
                  onClick={() => {
                    router.push('/feedback');
                  }}
                />
              </Popover>
            ) : null} */}

            <Image src={'/images/hairstyle/credit_icon.png'} alt={''} width={30} height={30} />
            <span className={styles.creditLabel}>{`Credit: `}</span>
            <span className={styles.creditCount}>{`${creditsCount}`}</span>
          </div>
          <Dropdown menu={{ items }} placement="bottomRight" autoAdjustOverflow={false}>
            <Button
              className={styles.emailIcon}
              onClick={() => {
                router.push('/dashboard');
              }}
            >
              {firstLetter}
              {shareUnreadCount > 0 && <p />}
            </Button>
          </Dropdown>
        </div>
      ) : (
        <Button
          className={styles.login}
          onClick={() => {
            onOpenLoginModal(true);
            onChangedLoginView(true);
          }}
        >
          {`${t('SignIn_SignUp')}`}
        </Button>
      )}
    </div>
  );
};

export default UserContent;
